import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/certAuthSession/`

const getSessions = () => {
  return axios.get(
    `${API_URL}getSessions`,
    getAxiosConfig())
  .then((response) => {
    return response.data;
  });
};

const services = {
  getSessions
}

export default services;
