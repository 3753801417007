
import { Grid, Container } from 'semantic-ui-react';
import ActionButtons from './ActionButtons';

export default function BottomMenuBar() {
  return (
    <Container>
      <Grid>
        <Grid.Row doubling={true} centered={true} columns={4}>
          <ActionButtons renderFn={item =>
            <Grid.Column style={{textAlign: 'center'}}>
              {item}
            </Grid.Column>}
          />
        </Grid.Row>
      </Grid>
    </Container>
  );
}
