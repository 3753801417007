import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import huMessages from './messages/hu.json'
import enMessages from './messages/en.json'

import 'semantic-ui-css/semantic.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const locale = process.env.REACT_APP_LOCALE;
let messages;

switch(locale) {
  case 'hu':
    messages = huMessages;
    break;
  case 'en':
    messages = enMessages;
    break;
  default:
    messages = huMessages;
    break;
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={messages}>
      <App/>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
