import { useEffect, useRef, useContext } from 'react';
import { Container, Message, Ref } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FormattedMessage } from 'react-intl';

import { DocumentContext } from '../../../../../reducers/Document/documentReducer';

import messages from '../../messages';

const styles = {
  container: {
    margin: '1em 0'
  }
}

export default function FinalizedMessage(props) {
  const { state } = useContext(DocumentContext);
  const { finalized } = state;

  let ref = useRef(null);

  useEffect(() => {
    if (finalized && ref.current) {
      scrollIntoView(ref.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'center',
      });
    }
  }, [finalized])

  return finalized ? (
    <Container text style={styles.container}>
      <Ref innerRef={ref}>
        <Message>
          <FormattedMessage {...messages.documentFinalized} />
        </Message>
      </Ref>
    </Container>
  ) : null;
}
