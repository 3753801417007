import { useContext } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { DocumentContext } from '../../../../../reducers/Document/documentReducer';
import messages from '../../messages';


export default function NavigationButtons() {
  const MIN_ZOOM = 0.5;
  const MAX_ZOOM = 8;

  const { state, dispatch } = useContext(DocumentContext);
  const { page, zoom, pageSizes, currentSignature } = state;

  const changePage = (type) => {
    dispatch( { type } );
  };

  const changeZoom = (type) => {
    dispatch( { type } );
  }

  return (
    <>
      <FormattedMessage {...messages.firstPage}>
        {txt =>
          <Popup content={txt} trigger={
            <Button
              disabled={page === 1 || currentSignature !== null}
              tabIndex="0"
              onClick={() => changePage('firstPage')}
              icon="angle double left"
            />}
          />
        }
      </FormattedMessage>
      <FormattedMessage {...messages.previousPage}>
        {txt =>
          <Popup content={txt} trigger={
            <Button
              disabled={page === 1 || currentSignature !== null}
              tabIndex="1"
              onClick={() => changePage('prevPage')}
              icon="angle left"
            />}
          />
        }
      </FormattedMessage>
      <FormattedMessage {...messages.nextPage}>
        {txt =>
          <Popup content={txt} trigger={
            <Button
              disabled={page >= pageSizes.length || currentSignature !== null}
              tabIndex="2"
              onClick={() => changePage('nextPage')}
              icon="angle right"
            />}
          />
        }
      </FormattedMessage>
      <FormattedMessage {...messages.lastPage}>
        {txt =>
          <Popup content={txt} trigger={
            <Button
              disabled={page >= pageSizes.length || currentSignature !== null}
              tabIndex="3"
              onClick={() => changePage('lastPage')}
              icon="angle double right"
            />}
          />
        }
      </FormattedMessage>
      {zoom < MAX_ZOOM && (
        <FormattedMessage {...messages.zoomIn}>
          {txt =>
            <Popup content={txt} trigger={
              <Button
                tabIndex="4"
                onClick={() => changeZoom('zoomIn')}
                disabled={currentSignature !== null}
                icon="zoom-in"
              />}
            />
          }
        </FormattedMessage>
      )}
      {zoom > MIN_ZOOM && (
        <FormattedMessage {...messages.zoomOut}>
          {txt =>
            <Popup content={txt} trigger={
              <Button
                tabIndex="5"
                onClick={() => changeZoom('zoomOut')}
                disabled={currentSignature !== null}
                icon="zoom-out"
              />}
            />
          }
        </FormattedMessage>
      )}
    </>
  );
}
