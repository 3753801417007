import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MainPage from './pages/Main';
import NotFoundPage from './pages/NotFound';
import { defaultConfig } from './reducers/configContext';

function App() {

  const { startPageCaption } = defaultConfig;

  return (
    <main>
      <Helmet>
        <title>
          {startPageCaption}
        </title>
      </Helmet>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
