import { useContext, useCallback, useEffect } from 'react';
import { Container, Dimmer, Divider, Loader, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { DocumentContext } from '../../../../reducers/Document/documentReducer';
import { ConfigContext } from '../../../../reducers/configContext';

import clientService from '../../../../services/client';
import { useDidMountEffect } from '../../../../libs/hooks';
import { secureFetch } from '../../../../libs/utils';

import TopMenuBar from './Menu/TopMenuBar';
import BottomMenuBar from './Menu/BottomMenuBar';
import FloatingMenuBar from './Menu/FloatingMenuBar';

import ErrorMessage from '../../../../components/ErrorMessage';
import ImageView from './ImageView';
import DownloadMessage from './Notifications/DownloadMessage';
import FinalizedMessage from './Notifications/FinalizedMessage';

import messages from '../messages';

const styles = {
  loadingSegment: {
    maxWidth: '768px',
    minHeight: '100px',
    margin: '0px auto',
  }
}

export default function DocumentView() {
  const { state, dispatch } = useContext(DocumentContext);
  const config = useContext(ConfigContext);
  const { showFloatingMenu } = config;

  const {
    changeNumber,
    image,
    page,
    pageSizes,
    workflowId,
    zoom,
    error
  } = state;

  const loadDocumentData = useCallback(async() => {
    await secureFetch(
      () => clientService.getDocumentData(workflowId),
      (response) => dispatch({
        type: 'loadDocumentData',
        data: response
      }),
      (error) => dispatch({
        type: 'setErrorMessage',
        error
      }),
      'Nem sikerült lekérdezni a dokumentum adatait'
    );
  }, [workflowId, dispatch]);

  const loadPage = useCallback(async() => {
    await secureFetch(
      () => clientService.getImage(
        workflowId,
        page,
        zoom),
      (response) => {
        dispatch({ type: 'setImage', image : response.image});
      },
      (error) => dispatch({
        type: 'setErrorMessage',
        error
      }),
      'Nem sikerült lekérdezni a dokumentum adatait'
    );
  }, [workflowId, page, zoom, dispatch]);

  useEffect(() => loadPage(), [page, changeNumber, loadPage]);
  useEffect(() => loadDocumentData(), [changeNumber, loadDocumentData]);

  const init = async() => {
    await loadDocumentData();
    await loadPage();
  }

  useDidMountEffect(init);

  return (
    <>
      {!showFloatingMenu && (<TopMenuBar/>)}
      {showFloatingMenu && (<FloatingMenuBar/>)}
      <Container>
        <DownloadMessage/>
        <FinalizedMessage/>
        <Divider/>
      </Container>
      {error && (<ErrorMessage error={error}/>)}
      {(pageSizes.length > 0 && image != null
          ? (
            <ImageView/>
          ) : (!error && (
            <Segment style={styles.loadingSegment}>
              <Dimmer active={true} inverted={true}>
                <Loader>
                  <FormattedMessage {...messages.loading} />
                </Loader>
              </Dimmer>
            </Segment>
          ))
      )}
      <Container>
        <Divider/>
      </Container>
      {!showFloatingMenu && (<BottomMenuBar/>)}
    </>
  )
}
