import { createContext } from 'react';

export const documentReducer = (state, action) => {
  switch (action.type) {
    case 'changeZoom':
      const { zoom } = action;

      return {
        ...state,
        zoom
      }

    case 'setImage': {
      const { image } = action;

      return {
        ...state,
        image
      }
    }

    case 'loadDocumentData': {
      const { data } = action;
      const {
        signatureFields,
        pageSizes,
        isDemo,
        unsignedSignatureFields
      } = data;

      const {
        originallyUnsignedFields
      } = state;

      return {
        ...state,
        ...data,
        originallyUnsignedFields:
          originallyUnsignedFields ??
          unsignedSignatureFields ??
          signatureFields.length,
        isDemo: isDemo ?? false,
        areFieldsEditable: !signatureFields.some(s => s.signed),
        pageSizes: Object.entries(pageSizes)
      }
    }

    case 'finalizeDocument': {
      return {
        ...state,
        finalized: true,
        lastAction: 'finalize'
      }
    }

    case 'finalizeDocumentStarted': {
      return {
        ...state,
        finalizeInProgress: true
      }
    }

    case 'finalizeDocumentStopped': {
      return {
        ...state,
        finalizeInProgress: false
      }
    }

    case 'terminateDocument': {
      return {
        ...state,
        lastAction: 'terminate'
      }
    }

    case 'fieldsFilled': {
      let { changeNumber } = state;
      changeNumber++;

      return {
        ...state,
        changeNumber
      }
    }

    case 'closeDocument': {
      return {
        ...state,
        lastAction: 'close'
      }
    }

    case 'startSignature': {
      const {
        documentHash,
        fieldId,
        biometricCertIssuer,
        biometricCertSerial } = action;

      const { signatureFields } = state;
      const currentSignature = signatureFields
        .find(field => field.fieldId === fieldId);

        return {
        ...state,
        currentSignature,
        documentHash,
        biometricCertIssuer,
        biometricCertSerial
      }
    }

    case 'cancelSignature': {
      return {
        ...state,
        currentSignature: null,
        documentHash: null
      }
    }

    case 'finishSignature': {
      let { changeNumber } = state;
      changeNumber++;
      return {
        ...state,
        currentSignature: null,
        documentHash: null,
        changeNumber
      }
    }

    case 'setTextFieldValue': {
      const { fieldId, value } = action;
      let textBoxFields = [...state.textBoxFields];
      const fieldIdx = textBoxFields.findIndex(f => f.fieldId === fieldId);
      const textBoxField = {
        ...textBoxFields[fieldIdx],
        value
      };
      textBoxFields[fieldIdx] = textBoxField;

      return {
        ...state,
        textBoxFields
      };
    }

    case 'checkField': {
      const { fieldId } = action;
      let checkboxFields = [...state.checkboxFields];
      const fieldIdx = checkboxFields.findIndex(f => f.fieldId === fieldId);
      let checkboxField = {
        ...checkboxFields[fieldIdx],

      };
      checkboxField.checked = !checkboxField.checked;
      checkboxFields[fieldIdx] = checkboxField;

      return {
        ...state,
        checkboxFields
      }
    }

    case 'checkCheckboxGroupField': {
      const { groupId, fieldId } = action;
      let checkboxGroupFields = [...state.checkboxGroupFields];
      const groupIdx = checkboxGroupFields
        .findIndex(f => f.groupId === groupId);

      let checkboxGroup = {
        ...checkboxGroupFields[groupIdx]
      };

      const { multiple, fields } = checkboxGroup;
      let newFields = [...fields];

      if (multiple) {
        let checkboxFieldIdx = newFields.findIndex(f => f.fieldId === fieldId);
        let checkboxField = {
          ...newFields[checkboxFieldIdx],
        };
        checkboxField.checked = !checkboxField.checked;
        newFields[checkboxFieldIdx] = checkboxField;
      } else {
        for(let i = 0; i < newFields.length; i++) {
          newFields[i].checked = newFields[i].fieldId === fieldId;
        }
      }

      checkboxGroup = {
        ...checkboxGroup,
        fields: newFields
      }
      checkboxGroupFields[groupIdx] = checkboxGroup;

      return {
        ...state,
        checkboxGroupFields
      }
    }

    case 'zoomIn': {
      const { zoom } = state;

      return {
        ...state,
        zoom: zoom * 1.25,
        image: null
      }
    }

    case 'zoomOut': {
      const { zoom } = state;
      return {
        ...state,
        zoom: zoom * 0.8,
        image: null
      }
    }

    case 'firstPage': {
      let { page, image } = state;

      if (page > 1) {
        page = 1;
        image = null;
      }

      return {
        ...state,
        page,
        image
      }
    }

    case 'prevPage': {
      let { page, image } = state;
      if (page > 1) {
        page--;
        image = null;
      }

      return {
        ...state,
        page,
        image
      }
    }

    case 'nextPage': {
      let { page, pageSizes, image } = state;
      if (page < pageSizes.length) {
        page++;
        image = null;
      }

      return {
        ...state,
        page,
        image
      }
    }

    case 'lastPage': {
      let { page, pageSizes, image } = state;
      if (page < pageSizes.length) {
        page = pageSizes.length;
        image = null;
      }

      return {
        ...state,
        page,
        image
      }
    }

    case 'setErrorMessage': {
      const { error } = action;

      return {
        ...state,
        error
      }
    }

    case 'downloadDocument': {
      const { document, fileName } = action;

      return {
        ...state,
        pdfDocument: document,
        fileName
      }
    }

    default:
      return state;
  }
};

export const initialDocumentState = {
  page: 1,
  zoom: 0.64,
  currentSignature: null,
  pageSizes: [],
  signatureFields: [],
  checkboxFields: [],
  checkboxGroupFields: [],
  textFields: [],
  changeNumber: 0
}

export const DocumentContext = createContext();
