import { useState, useContext } from 'react';
import { Grid, Button } from 'semantic-ui-react';

import { secureFetch } from '../../../../libs/utils';
import { useDidMountEffect } from '../../../../libs/hooks';
import demoService from '../../../../services/demo';
import { StartContext } from '../../../../reducers/Start/startReducer';

export default function SampleDocuments() {
  const { dispatch } = useContext(StartContext);
  const [availableDocuments, setAvailableDocuments] = useState([]);

  const getSampleDocuments = async () => {
    await secureFetch(
      () => demoService.getSampleDocuments(),
      (response) => {
        setAvailableDocuments(response.sampleDocuments);
      },
      (error) => dispatch({
        type: 'setErrorMessage',
        error
      }),
      'Nem sikerült betölteni a dokumentumokat'
    );
  }

  const startSampleDocument = async (sampleDocumentPath) => {
    await secureFetch(
      () => demoService.startSampleDocument(sampleDocumentPath),
      (response) => {
        window.location.replace(
          `${window.location.href}` +
          `?token=${response.token}` +
          `&workflowId=${response.workflowId}`);
      },
      (error) => dispatch({
        type: 'setErrorMessage',
        error
      }),
      'Nem sikerült betölteni a dokumentumot'
    );
  }

  useDidMountEffect(getSampleDocuments);

  return (
    <Grid centered relaxed>
      {availableDocuments.map(doc =>
        (
          <Grid.Row>
            <Button
              onClick={() => startSampleDocument(doc.path)}
            >
              {doc.caption}
            </Button>
          </Grid.Row>
        )
      )}
    </Grid>
  );
}
