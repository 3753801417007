import { useRef, useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';

import  {
  DocumentContext,
  documentReducer,
  initialDocumentState
} from '../../../reducers/Document/documentReducer';

import Captions from './Captions';
import DocumentView from './DocumentView';

export default function Document(props) {
  const { workflowId } = props;

  const documentState = {
    ...initialDocumentState,
    workflowId,
  };

  const [ state, dispatch ] = useReducer(documentReducer, documentState);

  const {
    page,
    pageSizes,
    lastAction,
    currentSignature
  } = state;

  const appWrapperRef = useRef(null);

  const updateDimensions = () => {
    if (appWrapperRef.current && pageSizes && pageSizes.length > 0) {

      let zoom;
      if (currentSignature) {
        const maxWidth = Math.min(
          appWrapperRef.current.offsetWidth - 64,
          400,
        );
        zoom = maxWidth / currentSignature.position.width;
      }
      else {
        const maxWidth = Math.min(
          appWrapperRef.current.offsetWidth - 32,
          768,
        );
        zoom = maxWidth / pageSizes[page - 1][1].width;
      }

      dispatch({ type: 'changeZoom', zoom });
    }
  }

  useEffect(updateDimensions, [pageSizes, page, currentSignature]);

  useEffect(() => {
    const debouncedHandleResize = debounce(updateDimensions, 100);
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  });

  return (
      (
        <DocumentContext.Provider value={{ state, dispatch }}>
          <div ref={appWrapperRef}>
            {lastAction
              ? <Captions/>
              : <DocumentView/>
            }
          </div>
        </DocumentContext.Provider>
      )
  );
}
