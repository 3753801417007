import checkboxChecked from '../../../../../../images/checkboxchecked.png';
import checkboxUnchecked from '../../../../../../images/checkboxunchecked.png';

export default function CheckboxField(props) {
  const { field, zoom, dispatch } = props;
  const { fieldId, checked, position } = field;
  const { x, y, width, height } = position;

  const img = checked ? checkboxChecked : checkboxUnchecked;

  const onClick = () => {
    dispatch({
      type: 'checkField',
      fieldId: fieldId
    })
  }

  return (
    <div
      id={field.fieldId}
      role="button"
      onClick={() => onClick(fieldId)}
      style={{
        outline: 'none',
        backgroundImage: `url(${img})`,
        backgroundSize: '100% 100%',
        position: 'absolute',
        overflow: 'visible',
        zIndex: 100,
        left: Math.floor(x * zoom),
        top: Math.floor(y * zoom) ,
        width: Math.ceil(width * zoom),
        height: Math.ceil(height * zoom),
      }}
    />
  );
}
