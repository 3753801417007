import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useDidMountEffect } from '../../libs/hooks'
import { secureFetch } from '../../libs/utils'
import clientService from '../../services/client';
import { defaultConfig, ConfigContext } from '../../reducers/configContext';

import StartPage from './Start';
import DocumentPage from './Document';

export default function Main() {
  const [config, setConfig] = useState(defaultConfig);

  const getConfig = async() => {
    await secureFetch(
      () => clientService.getConfiguration(),
      (response) => {
        setConfig({
          ...config,
          ...response.configuration
        });
      },
      () => {},
      'Nem sikerült lekérdezni a beállításokat'
    );
  }

  const navigate = useNavigate();
  let [ searchParams ] = useSearchParams();

  let workflowId = searchParams.get('workflowId');
  let token = searchParams.get('token');

  if (token) {
      sessionStorage.setItem('token', token);
      navigate(`${window.location.pathname}?workflowId=${workflowId}`);
  } else if (
    !sessionStorage.getItem('token') &&
    localStorage.getItem('token')) {
    sessionStorage.setItem('token', localStorage.getItem('token'));
  }

  useDidMountEffect(() => getConfig());

  const { startPageCaption } = config;

  return (
    <ConfigContext.Provider value={config}>
      <Helmet>
        <title>
          {startPageCaption}
        </title>
      </Helmet>
      {workflowId !== null
        ? <DocumentPage workflowId={workflowId} token={token} />
        : <StartPage/>
      }
    </ConfigContext.Provider>
  );
}
