import checkboxChecked from '../../../../../../images/checkboxchecked.png';
import checkboxUnchecked from '../../../../../../images/checkboxunchecked.png';
import radioChecked from '../../../../../../images/radiochecked.png';
import radioUnchecked from '../../../../../../images/radiounchecked.png';

export default function CheckboxGroup(props) {
  const { group, dispatch, zoom, filter } = props;
  const { fields, groupId, multiple } = group;

  const onClick = (fieldId) => {
    dispatch({
      type: 'checkCheckboxGroupField',
      groupId,
      fieldId: fieldId
    })
  }

  const getImg = (multiple, checked) => {
    if (multiple && checked) return checkboxChecked;
    if (multiple && !checked) return checkboxUnchecked;
    if (!multiple && checked) return radioChecked;
    if (!multiple && !checked) return radioUnchecked;
  }

  return (
    fields
      .filter(filter)
      .map(field => {
        const { fieldId, checked, position } = field;
        const { x, y, width, height } = position;

        const img = getImg(multiple, checked);

        return (
          <div
            id={`${groupId}.${fieldId}`}
            role="button"
            onClick={() => onClick(fieldId)}
            style={{
              outline: 'none',
              backgroundImage: `url(${img})`,
              backgroundSize: '100% 100%',
              position: 'absolute',
              overflow: 'visible',
              zIndex: 1000,
              left: Math.ceil(x * zoom),
              top: Math.ceil(y * zoom) ,
              width: Math.ceil(width * zoom),
              height: Math.ceil(height * zoom),
            }}
          />
        );
    })
  );
}
