import { createContext } from 'react';

const {
  REACT_APP_SHOW_FLOATING_MENU,
  REACT_APP_START_PAGE_CAPTION,
  REACT_APP_SHOW_SAMPLE_DOCUMENTS,
  REACT_APP_SHOW_DOCUMENT_LIST,
  REACT_APP_SHOW_DOWNLOAD_BUTTON,
  REACT_APP_DELETE_DOCUMENT_ON_CLOSE,
  REACT_APP_ALLOW_EARLY_FINALIZATION,
  REACT_APP_SHOW_HELP
} = process.env;


export const defaultConfig = {
  showFloatingMenu: REACT_APP_SHOW_FLOATING_MENU === "true" ?? false,
  startPageCaption: REACT_APP_START_PAGE_CAPTION ?? "SignoWise Cloud",
  showSampleDocuments: REACT_APP_SHOW_SAMPLE_DOCUMENTS === "true" ?? false,
  showDocumentList: REACT_APP_SHOW_DOCUMENT_LIST === "true" ?? false,
  allowedInputs: [ 'pen', 'mouse', 'touch' ],
  showDownloadButton: REACT_APP_SHOW_DOWNLOAD_BUTTON === "true" ?? false,
  deleteDocumentOnClose: REACT_APP_DELETE_DOCUMENT_ON_CLOSE === "true" ?? true,
  allowEarlyFinalization: REACT_APP_ALLOW_EARLY_FINALIZATION === "true" ?? false,
  showHelp: REACT_APP_SHOW_HELP === "true" ?? false
}

export const ConfigContext = createContext();
