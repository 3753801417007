import { defineMessages } from 'react-intl';

export default defineMessages({
  errorOccured: 'Some error occured.',
  error1: 'Unknown error',
  error10: 'Could not close the session',
  error11: 'Could not fill the fields',
  error12: 'Could not download the document',
  error13: 'Could not create the token',
  error14: 'Could not fetch the configuration',
  error15: 'Empty signature field',
  error16: 'Server error',
  error17: 'Missing field',
  error18: 'Could not get the signature data',
  error19: 'Could not access the document',
  error2: 'Could not create the document',
  error3: 'Could not terminate the document',
  error4: 'Could not download the PDF document',
  error5: 'Could not fetch the document data',
  error6: 'The field is already signed',
  error7: 'Could not create the signature',
  error8: 'Could not close the document',
  error9: 'Could not find the document'
});
