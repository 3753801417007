import { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Ref, Message, Container } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { DocumentContext } from '../../../../../reducers/Document/documentReducer';

import { downloadFile } from '../../../../../libs/downloadHelper';
import messages from '../../messages';

export default function DownloadMessage() {
  const { state } = useContext(DocumentContext);
  const { pdfDocument, fileName } = state;
  const downloadRef = useRef(null);

  const download = () => downloadFile(fileName, pdfDocument);

  useEffect(() => {
    if (pdfDocument && downloadRef.current) {
      scrollIntoView(downloadRef.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'center',
      });
    }
  }, [pdfDocument]);

  return pdfDocument ? (
    <Container style={{ margin: '1em 0em' }}>
      <Ref innerRef={downloadRef}>
        <Message info>
          <FormattedMessage {...messages.downloadDescription} />
          <span
            role="button"
            onClick={download}
            tabIndex="0"
            style={{ cursor: 'pointer' }}
          >
            &nbsp;
            <FormattedMessage {...messages.download} />
          </span>
        </Message>
      </Ref>
    </Container>
  ) : null;
}
