import { useEffect, useRef } from 'react';
import { Container, Message, Ref } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const styles = {
  errorContainer: {
    margin: '1em 0'
  }
}

export default function ErrorMessage(props) {
  const { error } = props;
  const { errorCode, errorMessage } = error;

  let errorRef = useRef(null);

  useEffect(() => {
    if (error && errorRef.current) {
      scrollIntoView(errorRef.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'center',
      });
    }
  }, [error])

  return error ? (
    <Container text style={styles.errorContainer}>
      <Ref innerRef={errorRef}>
        <Message negative>
          <Message.Header>
            <FormattedMessage {...messages.errorOccured} />
          </Message.Header>
          <Message.Content>
          {errorCode === undefined
            ? errorMessage
            : ''
          }
          </Message.Content>
        </Message>
      </Ref>
    </Container>
  ) : null;
}
