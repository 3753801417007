import { secureFetch } from '../../../../../../libs/utils';
import clientService from '../../../../../../services/client';

export default function SignatureField(props) {

  const {
    field,
    zoom,
    dispatch,
    workflowId,
    areFieldsEditable,
    checkboxFields,
    checkboxGroups,
    textFields
  } = props;
  const { fieldId, position} = field;
  const { x, y, width, height } = position;


  const startSignature = async () => {
    if (areFieldsEditable) {
      await secureFetch(
        () => clientService.fillFields(
          workflowId,
          checkboxFields,
          checkboxGroups,
          textFields),
        (response) => dispatch({ type: 'fieldsFilled' }),
        (error) => dispatch({ type: 'setErrorMessage', error }),
        'Nem sikerült kitölteni az űrlapmezőket'
      );
    }

    await secureFetch(
      () => clientService.startSignature(workflowId, fieldId),
      (response) => {
        dispatch({
          type: 'startSignature',
          fieldId: fieldId,
          ...response
        });
      },
      (error) => dispatch({ type: 'setErrorMessage', error }),
      'Nem sikerült lekérdezni az aláírást'
    );
  }

  return (
    <div
      id={fieldId}
      role="button"
      onClick={startSignature}
      style={{
        backgroundColor: '#3EB4DC',
        opacity: 0.5,
        position: 'absolute',
        overflow: 'visible',
        zIndex: 1000,
        left: x * zoom,
        top: y * zoom,
        width: width * zoom,
        height: height * zoom,
      }}
    />
  );
}
